import React from "react";
import { MarkerPanel } from "./Marker.js";
import { LimitsPanel } from "./Limits.js";
import { StylePanel } from "./Style.js";
import { TextPanel } from "./Text.js";
import { WorldMeshPanel } from "./WorldMesh.js";
import { ImagePanel } from "./Image.js";
import { EditTextPill } from "../HeaderCommon.js";

function WorldLabelHeader(props) {
  const handleFind = (e) => {
    e.stopPropagation();
    Inventum.labels.zoomTo(props.id);
  };

  const handleLocation = (e) => {
    e.stopPropagation();
    Inventum.labels.locationControl(props.id);
    props.getLabels();
  };

  const handleClone = (e) => {
    e.stopPropagation();
    Inventum.labels.duplicate(props.id);
    props.getLabels();
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    Inventum.labels.delete(props.id);
    props.getLabels();
  };

  const addText = (text) => {
    Inventum.labels.addText(props.id, text);
    props.getLabels();
  };

  const handleVisible = (e) => {
    e.stopPropagation();
    Inventum.labels.toggleVisible(props.id);
    props.getLabels();
  };

  const handleToggleVisibleStart = (e) => {
    e.stopPropagation();
    Inventum.labels.toggleVisibleStart(props.id);
    props.getLabels();
  };

  const editText = (index, text) => {
    Inventum.labels.editText(props.id, index, text);
    props.getLabels();
  };

  return (
    <div onClick={props.toggleOpen} className="LabelHeader">
      <span className="PanelOpenStatus">{props.isOpen ? "▾" : "▸"}</span>
      <span className="LabelHeaderBtn delete" onClick={handleDelete} title="Delete Label">
        <i className="material-icons notranslate">clear</i>
      </span>
      <span className="LabelHeaderBtn" onClick={handleClone} title="Clone Label">
        <i className="material-icons notranslate">copy_all</i>
      </span>
      <span className="LabelHeaderBtn" onClick={handleFind} title="Find Label">
        <i className="material-icons notranslate">adjust</i>
      </span>
      <span className={"LabelHeaderBtn" + (props.transformActive ? " active" : "")} onClick={handleLocation} title="Move Label">
        <i className="material-icons notranslate">open_with</i>
      </span>
      <span
        className="LabelHeaderBtn"
        style={{ opacity: props.visibleStart ? 1.0 : 0.4 }}
        onClick={handleToggleVisibleStart}
        title={
          props.visibleStart
            ? "Label will appear when Scene Loads (Visible Start = True)"
            : "Label won't appear when scene loads (Visible Start = False)"
        }>
        <i className="material-icons notranslate">{props.visibleStart ? "tour" : "not_interested"}</i>
      </span>
      <span className={"LabelHeaderBtn" + (props.visible ? " active" : "")} onClick={handleVisible} title="Toggle Visible">
        <i className="material-icons notranslate">{props.visible ? "check_box" : "check_box_outline_blank"}</i>
      </span>
      {(() => {
        return (
          <div className="LabelTextPillContainer">
            <div className="ExistingLabelTextPills">
              {props.text.map((line, index) => (
                <EditTextPill
                  setText={(text) => {
                    editText(index, text);
                  }}
                  key={index}
                  value={line}
                  label={line}
                />
              ))}
            </div>
            <EditTextPill setText={addText} id={props.id} label="+" title="Add a new line" value="" />
          </div>
        );
      })()}
    </div>
  );
}

function WorldLabelDrawer(props) {
  const onImageLoad = (url) => {
    Inventum.labels.setLabelStyle(props.id, { image: url });
    props.getLabels();
  };

  return (
    <div style={{ padding: "5px" }}>
      <TextPanel {...props} />
      <MarkerPanel {...props} />
      <LimitsPanel {...props} />
      <StylePanel exclude={["image", "textAlign", "lineSpacing"]} {...props} />
      <ImagePanel url={props.style.image} onImageLoad={onImageLoad} {...props} />
      <WorldMeshPanel {...props} onPointerVisibilityChange={props.onPointerVisibilityChange} visible={props.visiblePointer} />
    </div>
  );
}

class WorldLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      pointerVisible: props.pointerVisible ?? true,
    };
  }

  handlePointerVisibilityChange = (newVisibility) => {
    this.setState({ pointerVisible: newVisibility });
  };

  render() {
    const toggleOpen = () => {
      this.setState({ open: !this.state.open });
    };
    return (
      <div>
        <WorldLabelHeader toggleOpen={toggleOpen} isOpen={this.state.open} pointerVisible={this.state.pointerVisible} {...this.props} />
        <div style={{ marginLeft: "5px", backgroundColor: "#303030" }}>
          {this.state.open ? (
            <WorldLabelDrawer
              {...this.props}
              labelVisible={this.props.visible}
              visiblePointer={this.state.pointerVisible}
              onPointerVisibilityChange={this.handlePointerVisibilityChange}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export { WorldLabel };

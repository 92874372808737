import React from 'react';
import { ChromePicker } from 'react-color';

class StylePanel extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      style: props.style,
      activeInput: null,
      open: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
		this.handleNumericChange = this.handleNumericChange.bind(this);
    this.updateStyle = this.updateStyle.bind(this);
  }

  componentDidUpdate (prevProps) {
    if (prevProps !== this.props) {
      this.setState({ style: this.props.style });
    }
  }

  handleChange (e) {
    let style = this.state.style;
    style[this.state.activeInput] = e.target.value;
    this.setState({style});
  }

	handleNumericChange (key, value) {
		if (isNaN(value)) return;

		let style = this.state.style;
		style[key] = Number(value);
		this.setState({style});
		let inventumStyle = JSON.parse(JSON.stringify(style)); // Clone
		Inventum.labels.setLabelStyle(this.props.id, inventumStyle);
		this.props.getLabels();
	}

  handleColorChange (value) {
    let style = this.state.style;
    style[this.state.activeInput] = value;
    this.setState({style});
    let inventumStyle = JSON.parse(JSON.stringify(style));
    Inventum.labels.setLabelStyle(this.props.id, inventumStyle);
    this.props.getLabels();
  }

  updateStyle (e) {
    e.stopPropagation();
    let style = JSON.parse(JSON.stringify(this.state.style));
    Inventum.labels.setLabelStyle(this.props.id, style);
    this.setState({activeInput:null});
    this.props.getLabels();
  }

  render () {
    return (
      <div>
        <div className='EditorDrawerItemHeader' onClick={() => { this.setState({ open: !this.state.open }) }}>
          <span className='PanelOpenStatus'>{this.state.open ? '▾' : '▸'}</span>
          <span>Style</span>
        </div>
        {this.state.open ? <StyleDrawer
           exclude={this.props.exclude}
           style={this.state.style}
           activeInput={this.state.activeInput}
           clearActiveInput={() => { this.setState({ activeInput: null }) }}
           handleChange={this.handleChange}
           handleColorChange={this.handleColorChange}
					 handleNumericChange={this.handleNumericChange}
           handleToggle={(key) => {if (this.state.activeInput) return; this.setState({activeInput:key})}}
           updateStyle={this.updateStyle}
           />
        : null}
      </div>
    )
  }
}

function StyleDrawer (props) {
  let style = props.style;
  const excludeList = props.exclude || [];
	const fieldInputTypes = {
    color: ["color", "backgroundColor"],
    numeric: ["padding", "lineSpacing", "paddingHeight", "borderRadius"],
    toggle: ["borderRadiusEnabled"],
    select: ["textAlign"],
    text: ["font"],
  };
  const LABELS = {
    backgroundColor: "Background Colour",
    color: "Colour",
    textAlign: "Text Align",
    padding: "Padding",
  };


	return (
    <div>
    {Object.keys(props.style).filter((key) => !excludeList.includes(key)).map((item, index) => {

			let inputType = 'text'; // Defaults to text input

			for (let fieldType in fieldInputTypes) {
				if (fieldInputTypes[fieldType].includes(item)) {
					inputType = fieldType;
					break;
				}
			}

      const label = LABELS[item] || item;

      if (inputType === "color") {
        return <ColorInput key={index} item={item} label={label} index={index} {...props} />;
      } else if (inputType === "numeric") {
        return <NumericInput key={index} item={item} label={label} index={index} {...props} />;
      } else if (inputType === "toggle") {
        return <ToggleInput key={index} item={item} label={label} index={index} {...props} />;
      } else if (inputType === "select") {
        return <SelectInput key={index} item={item} label={label} index={index} {...props} />;
      } else {
        return <TextInput key={index} item={item} label={label} index={index} {...props} />;
      }

    })}
    </div>
  )
}

function TextInput (props) {
  return (
    <div style={{ display:'flex', fontSize: '14px', marginBottom: '10px' }}>
      <div className='PanelKeyCell' title={props.label}>{props.label}</div>
      <div className='PanelValueCell' title={props.style[props.item]} onClick={() => {props.handleToggle(props.item)}}>
       { props.activeInput === props.item ? <div><input style={{ width: '100px' }} onChange={props.handleChange} value={props.style[props.item]} /> <span className='PanelValueCellUpdateBtn' onClick={props.updateStyle}>Update</span> </div> : <span>{props.style[props.item]}</span> }
      </div>
    </div>
  )
}

function NumericInput (props) {
	return (
		<div style={{ display:'flex', fontSize: '14px', marginBottom: '10px' }}>
			<div className='PanelKeyCell' title={props.label}>{props.label}</div>
			<div className='PanelValueCell' title={props.style[props.item]} onClick={() => {props.handleToggle(props.item)}}>
			  <div><input type="number" style={{ width: '100px' }} onChange={(e)=>{props.handleNumericChange(props.item, e.target.value)}} value={props.style[props.item]} /></div>
			</div>
		</div>
	)
}

function ToggleInput(props) {
  const { item, style, handleNumericChange } = props;

  return (
    <div style={{ display: "flex", fontSize: "14px", marginBottom: "10px" }}>
      <div className="PanelKeyCell" title={props.label}>
        {item}
      </div>
      <div className="PanelValueCell">
        <label>
          <input
            type="checkbox"
            checked={style[item]}
            onChange={(e) => {
              const newValue = e.target.checked;
              handleNumericChange(item, newValue ? 1 : 0);
            }}
          />
          {style[item] ? "Enabled" : "Disabled"}
        </label>
      </div>
    </div>
  );
}

function ColorInput (props) {
  return (
    <div style={{ display:'flex', fontSize: '14px', marginBottom: '10px' }}>
      <div className='PanelKeyCell' title={props.label}>{props.label}</div>
      <div className='ColorValueCell' style={{ background: props.style[props.item] }} onClick={() => {if (props.activeInput === props.item) { return; }; props.handleToggle(props.item)}}>
        { props.activeInput === props.item ? <ColorSelector handleClose={props.clearActiveInput} item={props.item} color={props.style[props.item]} handleChange={props.handleColorChange} /> : null }
      </div>
    </div>
  )
}

function ColorSelector (props) {
  return (
    <div>
      <ChromePicker onChange={(e) => { props.handleChange(`rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`); }} color={props.color} />
      <div className='ColorCloseButton' onClick={props.handleClose}>Close</div>
    </div>
  )
}

function SelectInput (props) {
	return (
		<div style={{ display:'flex', fontSize: '14px', marginBottom: '10px' }}>
			<div className='PanelKeyCell' title={props.label}>{props.label}</div>
			<div className='ColorValueCell' style={{ background: props.style[props.item] }} onClick={() => {if (props.activeInput === props.item) { return; }; props.handleToggle(props.item)}}>
				{ props.activeInput === props.item ? <ColorSelector handleClose={props.clearActiveInput} item={props.item} color={props.style[props.item]} handleChange={props.handleColorChange} /> : null }
			</div>
		</div>
	)
}

export { StylePanel }

import React from 'react';
import { EditTextPill } from '../HeaderCommon.js';

class TextPanel extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      open:false
    };
  }

  render () {
    return (
      <div>
        <div className='EditorDrawerItemHeader' onClick={() => { this.setState({ open: !this.state.open }) }}>
          <span className='PanelOpenStatus'>{this.state.open ? '▾' : '▸'}</span>
          <span>Text</span>
        </div>
        { this.state.open ? <TextDrawer {...this.props} /> : null }
      </div>
    )
  }
}

function TextDrawer (props) {
  const text = props.text;

	const addText = (text) => {
		Inventum.labels.addText(props.id, text);
		props.getLabels();
	};

  const clearAll = (e) => {
    Inventum.labels.clearText(props.id);
    props.getLabels();
  }

	const editText = (index, text) => {
		Inventum.labels.editText(props.id, index, text);
		props.getLabels();
	};

  const clearText = (index) => {
    Inventum.labels.clearTextLine(props.id, index);
    props.getLabels();
  }

  return (
    <div>
      <div className="EditorTextButtonsContainer">
        <div className="PanelKeyCell">Add Line</div>
        <EditTextPill setText={addText} id={props.id} label="+" title="Add a new line" value="" />
      </div>
      <div className="EditorLabelTextList">
        {text.map((line, index) => {
          return (
            <span
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                marginBottom: "5px",
                marginRight: "5px",
                padding: "4px",
                borderRadius: "5px",
                backgroundColor: "#5b5b5b",
              }}>
              <EditTextPill
                setText={(text) => {
                  editText(index, text);
                }}
                key={index}
                value={line}
                label={line}
              />
              <div
                title="Delete Text"
                className="TextPillDeleteButton"
                onClick={(e) => {
                  e.stopPropagation();
                  clearText(index);
                }}>
                <i style={{ fontSize: "14px" }} className="material-icons notranslate">
                  delete
                </i>
              </div>
            </span>
          );
        })}
      </div>
    </div>
  );
}

export { TextPanel }

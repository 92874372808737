import React, { useState, useEffect, useRef } from "react";
import { Button, Box, CircularProgress, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { styled } from "@mui/material/styles";
import axios from "../../../../../axios-config.js";
import { connect } from "react-redux";

class ImagePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    return (
      <div>
        <div
          className="EditorDrawerItemHeader"
          onClick={() => {
            this.setState({ open: !this.state.open });
          }}>
          <span className="PanelOpenStatus">{this.state.open ? "▾" : "▸"}</span>
          <span>Image</span>
        </div>
        {this.state.open ? (
          <ImageDrawer
            url={this.props.url}
            onImageLoad={this.props.onImageLoad}
            activeProjectID={this.props.activeProjectID}
            {...this.props}
          />
        ) : null}
      </div>
    );
  }
}

const StyledInput = styled("input")({
  display: "none",
});

const smallButtonStyle = {
  padding: "0.05rem 0.2rem",
  fontSize: "0.625rem",
  marginRight: "0.3rem",
};

function ImageDrawer(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [originalFileName, setOriginalFileName] = useState("");
  const [showFileName, setShowFileName] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (props.url) {
      const assetIdentifier = props.url.split("/").pop();
      setIsLoading(true);
      axios
        .get(`https://api.inventum3d.com/upload/original-filename/${assetIdentifier}`)
        .then((response) => {
          setOriginalFileName(response.data.originalFileName);
          setShowFileName(originalFileName !== "");
        })
        .catch((error) => {
          console.error("Error fetching original filename:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [props.url]);

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setShowFileName(false);
      setIsLoading(true);
      setOriginalFileName(file.name);
      const form = new FormData();
      form.append("asset", file);
      form.append("projectID", props.activeProjectID);
      axios
        .post("https://api.inventum3D.com/upload/", form)
        .then((response) => {
          if (response.data.url) {
            props.onImageLoad(response.data.url);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Upload error:", error);
          setIsLoading(false);
        });
    }
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  const handleUploadClick = () => {
    e.stopPropagation();
    if (!isFileDialogOpen) {
      setIsFileDialogOpen(true);
      fileInputRef.current && fileInputRef.current.click();
    }
  };

  const handleRemoveImage = () => {
    setOriginalFileName("");
    setShowFileName(false);
    props.onImageLoad("");
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(originalFileName)
      .catch((error) => {
        console.error("Copy to clipboard failed:", error);
      });
  };

  return (
    <Box sx={{ mb: 1, maxWidth: "99%" }}>
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 200 }}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && props.url && (
        <Box
          className="StaticImagePreview"
          sx={{
            backgroundImage: `url(${props.url})`,
            height: 200,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            transition: "height 0.3s ease",
          }}
        />
      )}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mt: 1 }}>
        <Box sx={{ justifyContent: "space-between" }}>
          <label htmlFor="icon-button-file">
            <StyledInput accept="image/*" id="icon-button-file" type="file" onChange={onFileChange} ref={fileInputRef} />
            <Button
              style={smallButtonStyle}
              variant="contained"
              color="secondary"
              component="span"
              onClick={handleUploadClick}
              disabled={isLoading}>
              Upload a file
            </Button>
          </label>
          {originalFileName && (
            <Button style={smallButtonStyle} variant="contained" color="secondary" onClick={() => setShowFileName(!showFileName)}>
              {showFileName ? "Hide file name" : "Show file name"}
            </Button>
          )}
        </Box>
        {originalFileName && (
          <Button style={smallButtonStyle} variant="outlined" color="error" onClick={handleRemoveImage}>
            Remove
          </Button>
        )}
      </Box>
      {showFileName && originalFileName && (
        <Box sx={{ display: "flex", alignItems: "center", wordBreak: "break-word" }}>
          <Box sx={{ flexGrow: 1 }}>{originalFileName}</Box>
          <IconButton size="small" onClick={handleCopyToClipboard} sx={{ ml: 1 }}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

let mapDispatchToProps = (dispatch) => ({});

let mapStateToProps = (state) => ({
  activeProjectID: state.ui.activeProject,
});

ImagePanel = connect(mapStateToProps, mapDispatchToProps)(ImagePanel);

export { ImagePanel };
